/*	12 COLUMN : RESPONSIVE GRID SYSTEM
	DEVELOPER : DENIS LEBLANC
	URL : http://responsive.gs
	VERSION : 3.0
	LICENSE : GPL & MIT */

/* 	SET ALL ELEMENTS TO BOX-SIZING : BORDER-BOX */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /*	If you need support for IE7 and lower make
		sure the boxsizing.htc file is linked properly.
		More info here:  https://github.com/Schepp/box-sizing-polyfill */
}

/*	MAIN CONTAINER
	Set the width to whatever you want the width of your site to be. */

.container {
  max-width: 73.125rem;
  margin: 0 auto;
}

/*	SELF CLEARING FLOATS - CLEARFIX METHOD */
.container:after,
.row:after,
.col:after,
.clr:after,
.group:after {
  content: '';
  display: table;
  clear: both;
}

/* 	DEFAULT ROW STYLES
	Set bottom padding according to preference */
.row {
  padding-bottom: 0em;
}

/* DEFAULT COLUMN STYLES */
.col {
  display: block;
  float: left;
  width: 100%;
}

@media (min-width: 768px) {
  .gutters .col {
    margin-left: 2%;
  }

  .gutters .col:first-child {
    margin-left: 0;
  }
}

/*	COLUMN WIDTH ON DISPLAYS +768px
	You might need to play with media queries here to suite your design. */
@media (min-width: 768px) {
  .grid_1 {
    width: 8.33333333333%;
  }
  .grid_2 {
    width: 16.6666666667%;
  }
  .grid_3 {
    width: 25%;
  }
  .grid_4 {
    width: 33.3333333333%;
  }
  .grid_5 {
    width: 41.6666666667%;
  }
  .grid_6 {
    width: 50%;
  }
  .grid_7 {
    width: 58.3333333333%;
  }
  .grid_8 {
    width: 66.6666666667%;
  }
  .grid_9 {
    width: 75%;
  }
  .grid_10 {
    width: 83.3333333333%;
  }
  .grid_11 {
    width: 91.6666666667%;
  }
  .grid_12 {
    width: 100%;
  }

  .gutters .grid_1 {
    width: 6.5%;
  }
  .gutters .grid_2 {
    width: 15%;
  }
  .gutters .grid_3 {
    width: 23.5%;
  }
  .gutters .grid_4 {
    width: 32%;
  }
  .gutters .grid_5 {
    width: 40.5%;
  }
  .gutters .grid_6 {
    width: 49%;
  }
  .gutters .grid_7 {
    width: 57.5%;
  }
  .gutters .grid_8 {
    width: 66%;
  }
  .gutters .grid_9 {
    width: 74.5%;
  }
  .gutters .grid_10 {
    width: 83%;
  }
  .gutters .grid_11 {
    width: 91.5%;
  }
  .gutters .grid_12 {
    width: 100%;
  }
}

@media (max-width: 1002px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
}
